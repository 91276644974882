import { RouteObject, createBrowserRouter, RouterProvider } from 'react-router-dom';

import { protectedRoutes } from './protected';
import { publicRoutes } from './public';
import storage from '@/utils/storage';

export const AppRoutes = () => {
  const user = storage.getToken();

  const routes: RouteObject[] = user ? protectedRoutes : publicRoutes;

  const router = createBrowserRouter([...routes]);

  return <RouterProvider router={router} />;
};
