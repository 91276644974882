import { lazyImport } from '@/utils/lazyImport';
import { Suspense } from 'react';
import { Outlet, RouteObject } from 'react-router-dom';

const { Login } = lazyImport(() => import('@/features/auth/routes/Login'), 'Login');
const { ForgotPassword } = lazyImport(() => import('@/features/auth/routes/ForgotPassword'), 'ForgotPassword');
const { RestorePassword } = lazyImport(() => import('@/features/auth/routes/RestorePassword'), 'RestorePassword');
const { Confirm } = lazyImport(() => import('@/features/auth/routes/Confirm'), 'Confirm');

const App = () => {
  return (
    <Suspense fallback={<div className="h-full w-full flex items-center justify-center"></div>}>
      <Outlet />
    </Suspense>
  );
};

export const publicRoutes: RouteObject[] = [
  {
    path: '/',
    element: <App />,
    children: [
      { path: '*', element: <Login /> },
      { path: '/', element: <Login />},
      {
        path: '/login',
        element: <Login />,
      },
      { path: '/forgot-password', element: <ForgotPassword /> },
      { path: '/restore-password/:id', element: <RestorePassword /> },
      { path: '/confirm/:id', element: <Confirm /> },
    
    ],
  },
];
