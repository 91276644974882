import storage from '@/utils/storage';

function logout() {
  storage.clearToken();
  storage.clearAki();
  storage.clearSak();

  window.location.pathname = '/login';
}

export { logout };
