import { Suspense } from 'react';
import { Outlet, RouteObject } from 'react-router-dom';
import { lazyImport } from '@/utils/lazyImport';
import { useClientStore } from '@/stores/clientStore';
import Layout from '@/components/layout';

const { DisplayConfigure } = lazyImport(() => import('@/features/display/routes/DisplayConfigure'), 'DisplayConfigure');
const { Displays } = lazyImport(() => import('@/features/display/routes/Displays'), 'Displays');
const { DisplayCreate } = lazyImport(() => import('@/features/display/routes/DisplayCreate'), 'DisplayCreate');
const { DisplayEdit } = lazyImport(() => import('@/features/display/routes/DisplayEdit'), 'DisplayEdit');

const { Dashboard } = lazyImport(() => import('@/features/misc/routes/Dashboard'), 'Dashboard');
const { NotFound } = lazyImport(() => import('@/features/misc/routes/NotFound'), 'NotFound');

const { PlayLists } = lazyImport(() => import('@/features/playlist/routes/PlayLists'), 'PlayLists');
const { PlayListEdit } = lazyImport(() => import('@/features/playlist/routes/PlayListEdit'), 'PlayListEdit');
const { PlayListCreate } = lazyImport(() => import('@/features/playlist/routes/PlaylistCreate'), 'PlayListCreate');

const { PageEdit } = lazyImport(() => import('@/features/page/routes/PageEdit'), 'PageEdit');
const { PageCreate } = lazyImport(() => import('@/features/page/routes/PageCreate'), 'PageCreate');
const { Pages } = lazyImport(() => import('@/features/page/routes/Pages'), 'Pages');

const { MediaLibrary } = lazyImport(() => import('@/features/media-library/routes/MediaLibrary'), 'MediaLibrary');

const { Themes } = lazyImport(() => import('@/features/theme/routes/Themes'), 'Themes');
const { ThemeCreate } = lazyImport(() => import('@/features/theme/routes/ThemeCreate'), 'ThemeCreate');
const { ThemeEdit } = lazyImport(() => import('@/features/theme/routes/ThemeEdit'), 'ThemeEdit');

const { DisplayPreview } = lazyImport(() => import('@/features/display/routes/DisplayPreview'), 'DisplayPreview');
const { PlaylistPreview } = lazyImport(() => import('@/features/playlist/routes/PlaylistPreview'), 'PlaylistPreview');

const { Schedules } = lazyImport(() => import('@/features/schedule/routes/Schedules'), 'Schedules');
const { ScheduleEdit } = lazyImport(() => import('@/features/schedule/routes/ScheduleEdit'), 'ScheduleEdit');
const { ScheduleCreate } = lazyImport(() => import('@/features/schedule/routes/ScheduleCreate'), 'ScheduleCreate');

const { Bookables } = lazyImport(() => import('@/features/bookable/routes/Bookables'), 'Bookables');
const { BookableEdit } = lazyImport(() => import('@/features/bookable/routes/BookableEdit'), 'BookableEdit');
const { BookableCreate } = lazyImport(() => import('@/features/bookable/routes/BookableCreate'), 'BookableCreate');
const { BookingItems } = lazyImport(() => import('@/features/booking/routes/BookingItems'), 'BookingItems');

const { Bookings } = lazyImport(() => import('@/features/booking/routes/Bookings'), 'Bookings');
const { BookingEdit } = lazyImport(() => import('@/features/booking/routes/BookingEdit'), 'BookingEdit');
const { BookingCreate } = lazyImport(() => import('@/features/booking/routes/BookingCreate'), 'BookingCreate');

const { Users } = lazyImport(() => import('@/features/user/routes/Users'), 'Users');
const { UserCreate } = lazyImport(() => import('@/features/user/routes/UserCreate'), 'UserCreate');
const { UserEdit } = lazyImport(() => import('@/features/user/routes/UserEdit'), 'UserEdit');

const App = () => {
  const venueId = useClientStore(state => state.venueId) ?? null;
  return (
    <Layout>
      <Suspense fallback={<div className="h-full w-full flex items-center justify-center"></div>}>{venueId ? <Outlet /> : ''}</Suspense>
    </Layout>
  );
};

export const protectedRoutes: RouteObject[] = [
  {
    path: '/',
    element: <App />,
    children: [
      { path: '/', element: <Dashboard /> },
      { path: '/dashboard', element: <Dashboard /> },
      { path: '*', element: <NotFound /> },

      { path: '/displays', element: <Displays /> },
      { path: '/displays/create', element: <DisplayCreate /> },
      { path: '/displays/configure/:id', element: <DisplayConfigure /> },
      { path: '/displays/edit/:id', element: <DisplayEdit /> },
      { path: '/displays/:id/preview', element: <DisplayPreview /> },

      { path: '/playlists', element: <PlayLists /> },
      { path: '/playlists/:id', element: <PlayListEdit /> },
      { path: '/playlists/create', element: <PlayListCreate /> },
      { path: '/playlists/:id/preview', element: <PlaylistPreview /> },

      { path: '/pages', element: <Pages /> },
      { path: '/pages/:id', element: <PageEdit /> },
      { path: '/pages/create', element: <PageCreate /> },

      { path: '/themes', element: <Themes /> },
      { path: '/themes/create', element: <ThemeCreate /> },
      { path: '/themes/:id', element: <ThemeEdit /> },

      { path: '/schedules', element: <Schedules /> },
      { path: '/schedules/:id', element: <ScheduleEdit /> },
      { path: '/schedules/create', element: <ScheduleCreate /> },

      { path: '/bookables', element: <Bookables /> },
      { path: '/bookables/:id', element: <BookableEdit /> },
      { path: '/bookables/create', element: <BookableCreate /> },
      { path: '/booking-items', element: <BookingItems /> },

      { path: '/bookings', element: <Bookings /> },
      {
        path: '/bookings/edit/:id',
        element: <BookingEdit />,
      },
      {
        path: '/bookings/create',
        element: <BookingCreate />,
      },

      { path: '/users', element: <Users /> },
      { path: '/users/create', element: <UserCreate /> },
      { path: '/users/:id', element: <UserEdit /> },

      {
        path: '/media-library',
        element: <MediaLibrary />,
      },
    ],
  },
];
