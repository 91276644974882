import Axios, { AxiosRequestConfig } from 'axios';
import { API_URL } from '@/config';
import storage from '@/utils/storage';

function authRequestInterceptor(config: AxiosRequestConfig) {
  const token = storage.getToken();

  if (!config.headers) config.headers = {};

  if (token) {
    config.headers.authorization = `${token}`;
  }

  config.headers.Accept = 'application/json';
  return config;
}

export const axios = Axios.create({
  baseURL: API_URL,
});

axios.interceptors.request.use(authRequestInterceptor as any);
axios.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    if (error.response?.status === 401) {
      storage.setRedirectUrl(window.location.href);

      storage.clearToken();
      storage.clearAki();
      storage.clearSak();

      window.location.href = window.location.href;

      //Get a new token with refresh token
    } else {
      return Promise.reject(error);
    }
  }
);
