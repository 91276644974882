const storagePrefix = 'signage_app_';

const storage = {
  getUser: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}user`) as string);
  },
  getToken: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}token`) as string);
  },
  setToken: (token: string) => {
    window.localStorage.setItem(`${storagePrefix}token`, JSON.stringify(token));
  },
  clearToken: () => {
    window.localStorage.removeItem(`${storagePrefix}token`);
  },
  getLastestUrl: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}lastestUrl`) as string);
  },
  setRedirectUrl: (url: string) => {
    window.localStorage.setItem(`${storagePrefix}redirect-url`, JSON.stringify(url));
  },
  clearRedirectUrl: () => {
    window.localStorage.removeItem(`${storagePrefix}redirect-url`);
  },
  getRedirectUrl: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}redirect-url`) as string);
  },
  getClientId: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}clientId`) as string);
  },
  getSak: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}sak`) as string);
  },
  setSak: (sak: string) => {
    window.localStorage.setItem(`${storagePrefix}sak`, JSON.stringify(sak));
  },
  getAki: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}aki`) as string);
  },
  setAki: (aki: string) => {
    window.localStorage.setItem(`${storagePrefix}aki`, JSON.stringify(aki));
  },
  clearAki: () => {
    window.localStorage.removeItem(`${storagePrefix}aki`);
  },
  clearSak: () => {
    window.localStorage.removeItem(`${storagePrefix}sak`);
  },
};

export default storage;
