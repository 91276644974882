import { Link, useLocation } from 'react-router-dom';
import { CalendarDays, CalendarPlus, CalendarRange, LayoutDashboard, Library, ListVideo, MonitorPlay, Palette, PanelsTopLeft, Users } from 'lucide-react';

import { cn } from '@/utils';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { buttonVariants } from '@/components/ui/button';
import { VenueDropDownMenu } from '../common/VenueDropDownMenu';
import { LogoutButton } from '../../features/auth/components/LogoutButton';
import { ThemeSwitcher } from '../common/ThemeSwitcher';
import { SignyLogo } from '../SignyLogo';
import { SignyIcon } from '../SignyIcon';
import { useClientStore } from '@/stores/clientStore';

interface SidebarProps {
  isCollapsed: boolean;
}

//TODO add this to config or routes/navigations.ts

const routes = [
  {
    title: 'Dashboard',
    path: 'dashboard',

    icon: LayoutDashboard,
    variant: 'ghost',
  },

  {
    title: 'Sidor',
    path: 'pages',
    icon: PanelsTopLeft,
    variant: 'ghost' as any,
  },
  {
    title: 'Spellistor',
    path: 'playlists',

    icon: ListVideo,
    variant: 'ghost',
  },
  {
    title: 'Media',
    path: 'media-library',
    icon: Library,
    variant: 'ghost' as any,
  },
  {
    title: 'Skärmar',
    path: 'displays',
    icon: MonitorPlay,
    variant: 'ghost',
  },
  {
    title: 'Teman',
    path: 'themes',
    icon: Palette,
    variant: 'ghost',
  },
  // {
  //   title: 'Schemaläggning',
  //   path: 'schedules',
  //   icon: CalendarClock,
  //   variant: 'ghost',
  // },
  {
    title: 'Användare',
    path: 'users',
    icon: Users,
    variant: 'ghost',
  },

  {
    title: 'Bokningsbara enheter',
    path: 'bookables',
    icon: CalendarPlus,
    variant: 'ghost',
  },
  {
    title: 'Bokningsöversikt',
    path: 'booking-items',
    icon: CalendarRange,
    variant: 'ghost',
  },
  {
    title: 'Bokningar',
    path: 'bookings',
    icon: CalendarDays,
    variant: 'ghost',
  },
] as const;

export function Sidebar({ isCollapsed = true }: SidebarProps) {
  const { pathname } = useLocation();

  const clientId = useClientStore(state => state.clientId);

  return (
    <div
      className={cn(
        'h-screen border-r border-input bg-card hidden md:block',
        isCollapsed ? 'min-w-[50px] transition-all duration-300 ease-in-out' : 'w-[220px]'
      )}
    >
      <div data-collapsed={isCollapsed} className="group flex flex-col gap-4 h-full  data-[collapsed=true]:py-2 justify-between">
        <nav className="grid gap-1 px-2 group-[[data-collapsed=true]]:justify-center group-[[data-collapsed=true]]:px-2 py-2">
          {isCollapsed ? (
            <div className="w-[40px] flex justify-center mt-2 mb-6">
              <SignyIcon />
            </div>
          ) : (
            <div className="w-32 mt-4">
              <SignyLogo />
            </div>
          )}

          {!isCollapsed && (
            <div className="w-full mb-4 mt-6">
              <VenueDropDownMenu />
            </div>
          )}

          {routes
            .filter(route => (['bookables', 'bookings', 'booking-items'].includes(route.path) && clientId !== 4 && clientId !== 8 ? false : true))
            .map((link, index) =>
              isCollapsed ? (
                <Tooltip key={index} delayDuration={0}>
                  <TooltipTrigger asChild>
                    <Link
                      to={link.path}
                      className={cn(
                        buttonVariants({ variant: link.variant, size: 'icon' }),
                        'h-9 w-9',
                        pathname.includes(link.path) && 'bg-brand text-[white_!important] transition-[background] duration-1000',

                        link.variant === 'default' && 'dark:bg-muted dark:text-muted-foreground dark:hover:bg-muted dark:hover:text-white'
                      )}
                    >
                      <link.icon className="h-4 w-4" />
                      <span className="sr-only">{link.title}</span>
                    </Link>
                  </TooltipTrigger>
                  <TooltipContent side="right" className="flex items-center gap-4">
                    {link.title}
                  </TooltipContent>
                </Tooltip>
              ) : (
                <Link
                  key={index}
                  to={link.path}
                  className={cn(
                    buttonVariants({ variant: link.variant, size: 'sm' }),
                    link.variant === 'default' && 'dark:bg-muted dark:text-white dark:hover:bg-muted dark:hover:text-white',
                    pathname.includes(link.path) && 'bg-muted',
                    'justify-start relative'
                  )}
                >
                  {/* {pathname.includes(link.path) && <span className="absolute -left-2 w-1 rounded-sm h-8 bg-brand"></span>} */}
                  <link.icon className="mr-2 h-4 w-4" />
                  {link.title}
                </Link>
              )
            )}
        </nav>
        {/* TODO: add avatar and dropdown with options as theme and logout and user page */}

        <nav className="flex flex-col justify-end   py-2">
          <div className="p-2">
            <ThemeSwitcher isCollapsed={isCollapsed} />
          </div>
          <LogoutButton isCollapsed={isCollapsed} />
        </nav>
      </div>
    </div>
  );
}
