import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

type ClientStore = {
  venueId?: number | null;
  clientId?: number | null;
  setVenueId: (venueId: number) => void;
  setClientId: (clientId: number) => void;
};

export const useClientStore = create(
  persist<ClientStore>(
    set => ({
      venueId: null,
      clientId: null,
      setVenueId: venueId => set({ venueId }),
      setClientId: clientId => set({ clientId }),
    }),
    {
      name: 'client-storage',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
