export function SignyIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="66" height="41" viewBox="0 0 66 41" fill="none">
      <path
        d="M42.0403 18.5572C41.1618 19.351 41.1613 20.73 42.0394 21.5243L56.2493 34.3792C57.5349 35.5423 59.5905 34.6303 59.591 32.8966L59.5992 7.19468C59.5997 5.46099 57.5447 4.54786 56.2584 5.7102L42.0403 18.5572Z"
        fill="url(#paint0_linear_126_6)"
      />
      <path
        d="M21.8583 18.4339C20.9739 19.2283 20.9734 20.6141 21.8574 21.409L36.0574 34.1785C37.3444 35.3359 39.3943 34.4228 39.3948 32.6919L39.4029 7.16072C39.4034 5.42985 37.3542 4.51564 36.0665 5.67226L21.8583 18.4339Z"
        fill="url(#paint1_linear_126_6)"
      />
      <path
        d="M1.64559 18.5757C0.767938 19.3694 0.767501 20.7474 1.64465 21.5416L15.856 34.4093C17.1415 35.5732 19.1979 34.6614 19.1984 32.9273L19.2066 7.19977C19.2071 5.46566 17.1513 4.5527 15.8651 5.71588L1.64559 18.5757Z"
        fill="url(#paint2_linear_126_6)"
      />
      <defs>
        <linearGradient id="paint0_linear_126_6" x1="48.7562" y1="20.9356" x2="66.0041" y2="20.6337" gradientUnits="userSpaceOnUse">
          <stop stopColor="#AA81FF" stopOpacity="0.76" />
          <stop offset="1" stopColor="#7457B1" stopOpacity="0.76" />
        </linearGradient>
        <linearGradient id="paint1_linear_126_6" x1="28.56" y1="20.8109" x2="45.8078" y2="20.5073" gradientUnits="userSpaceOnUse">
          <stop stopColor="#AA81FF" stopOpacity="0.76" />
          <stop offset="1" stopColor="#7457B1" stopOpacity="0.76" />
        </linearGradient>
        <linearGradient id="paint2_linear_126_6" x1="8.36365" y1="20.9542" x2="25.6115" y2="20.6527" gradientUnits="userSpaceOnUse">
          <stop stopColor="#AA81FF" stopOpacity="0.76" />
          <stop offset="1" stopColor="#7457B1" stopOpacity="0.76" />
        </linearGradient>
      </defs>
    </svg>
  );
}
