import { ReactNode, useState } from 'react';
import { Sidebar } from './Sidebar';
import { Button } from '../ui/button';
import { ChevronsLeftRight, ChevronsRightLeft } from 'lucide-react';
import { cn } from '@/utils';

export default function Layout({ children }: { children: ReactNode }) {
  const [isCollapsed, setIsCollapsed] = useState(false);
  return (
    <div className="flex">
      <div className="w-fit fixed z-40">
        <div className="w-fit relative">
          <Sidebar isCollapsed={isCollapsed} />
          <Button
            variant="ghost"
            className="absolute top-1 hidden md:flex -right-2 bg-accent w-5 p-0 h-5 border-input border cursor-pointer"
            size="sm"
            onClick={() => setIsCollapsed(!isCollapsed)}
          >
            {isCollapsed ? <ChevronsLeftRight className="h-3 w-3" /> : <ChevronsRightLeft className="h-3 w-3" />}
          </Button>
        </div>
      </div>
      <main className={cn('bg-background w-full min-h-screen', isCollapsed ? 'ml-[50px]' : 'md:ml-[220px]')}>{children}</main>
    </div>
  );
}
