import { Button } from '../../../components/ui/button';
import { LogOut } from 'lucide-react';
import { cn } from '@/utils';
import { logout } from '@/features/auth/api/logout';

export function LogoutButton({ isCollapsed = true }: { isCollapsed?: boolean }) {
  return (
    <Button
      onClick={logout}
      size="sm"
      className={cn(
        'dark:bg-muted dark:text-white dark:hover:bg-muted dark:hover:text-white',
        'justify-start flex items-center mx-2 h-10 text-sm font-semibold',
      )}
    >
      <LogOut className={cn('h-4 w-4', isCollapsed ? 'mr-0' : 'mr-2')} />
      {!isCollapsed && 'Logga ut'}
    </Button>
  );
}
