import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuCheckboxItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { ChevronsUpDown, Moon, Sun } from 'lucide-react';
import { useTheme } from '@/providers/theme';

const themes = [
  {
    id: 'light',
    name: 'Ljust',
    icon: <Sun className="w-4 h-4 mr-2" />,
  },
  {
    id: 'dark',
    name: 'Mörkt',
    icon: <Moon className="w-4 h-4 mr-2" />,
  },
] as const;

export function ThemeSwitcher({ isCollapsed = false }: { isCollapsed?: boolean }) {
  const { setTheme, theme } = useTheme();

  if (isCollapsed) {
    return (
      <div className='m-3'>
        {theme === 'light' ? <Moon onClick={() => setTheme('dark')} className="h-4 w-4" /> : <Sun onClick={() => setTheme('light')} className="h-4 w-4" />}
      </div>
    );
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="border rounded-md h-10 px-3 w-full flex gap-x-2 items-center bg-accent justify-between">
        <div className="flex gap-x-2 items-center w-full">
          {themes?.find(t => t.id === theme)?.icon}
          <span className="font-semibold text-sm"> {themes?.find(({ id }) => id === theme)?.name ?? 'Inget valt'}</span>
        </div>
        <ChevronsUpDown className="w-4 h-4" />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-[200px]">
        <DropdownMenuLabel>Tema</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {themes?.map(({ id, icon, name }) => (
          <DropdownMenuCheckboxItem checked={theme === id} onCheckedChange={() => setTheme(id)} key={id}>
            {icon}
            {name}
          </DropdownMenuCheckboxItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
