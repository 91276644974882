import { AppRoutes } from './routes';
import { AppProvider } from './providers/app';
import { Toaster } from '@/components/ui/toaster';
import * as Sentry from '@sentry/react';

function App() {
  return (
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <AppProvider>
        <Toaster />
        <AppRoutes />
      </AppProvider>
    </Sentry.ErrorBoundary>
  );
}

export default App;
